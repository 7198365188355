@import "~/frontend/scss/stage0";

$primary: #08182f;
$secondary: #c42026;
$enable-gradients: true;
$gradient: linear-gradient(180deg, rgba(#000, 0), rgba(#000, 0.2)) !default;

@import "~/frontend/scss/stage1";

// dropdown
$dropdown-dark-bg: $secondary;
$dropdown-dark-link-color: $light;
// navbar
$navbar-light-toggler-border-color: $light;
$navbar-dark-toggler-border-color: $dark;
// forms
$dealersite-invert-form: true;

@import "~/frontend/scss/stage2";
